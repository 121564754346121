@use "../../app";

.footer {
  background: var(--primary-background);
  position:relative;
  color:#FFFFFF;


  p{
    padding-bottom: 6px;
  }
  .logo-footer{
    max-width: 30px;
    @media(max-width: app.$mobile-breakpoint) {
      max-width: 20px;
    }
  }

  .white-bg{
    background:#FFFFFF;
  }
  .download-button{
    gap:10px;

    img{
      width:100%;
      height:80px;
      object-fit: contain;
    }
  }

  .title {
    line-height: 2.8rem;
  }

  .container {
    display: flex;
    transition: all 1s ease;

    .content {
      padding-top: 10%;
    }
  }


  form input {
    padding: 12px 50px;
    background: #F0F2F8;
    text-align: center;
    border-radius: 20px;
  }

  #mockup-base {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    left:45%;
    @media(max-width: app.$mobile-breakpoint) {
      left:100px;
    }


    img {
      height:100%;
      max-height: 100%;
      width: auto;
      max-height: 680px;
      max-width: 380px;
      object-fit: contain;

      @media(max-width: app.$mobile-breakpoint) {
        max-width:200px;
      }
    }
  }

  #mockup-base-2 {
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    text-align: center;
    @media(max-width: app.$mobile-breakpoint) {
      left:0px;
    }


    img {
      height:100%;
      max-height: 100%;
      margin:auto;
      width: auto;
      max-height: 680px;
      max-width: 380px;
      object-fit: contain;

      @media(max-width: app.$mobile-breakpoint) {
        max-width:200px;
      }
    }
  }

  .app-mockup{
    position:absolute;
    max-height: 680px;
    max-width: 380px;
    object-fit: contain;
    width:80%;
    left:30px;
    top:-200px;

    @media(max-width: app.$mobile-breakpoint) {
      left:0px;
      top:0px;
      max-width: 200px;
    }

  }



  .image-section{
    height: 100%;
    margin-bottom: 30px;

    @media(max-width: app.$tablet-breakpoint) {
      img {
        max-height: 200px;
      }
    }
  }

}