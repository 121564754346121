@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@font-face {
  font-family: 'Rexton';
  src: local('rexton'), url(./assets/fonts/rexton.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/fonts/muli/Muli.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/fonts/muli/Muli-Bold.ttf) format('truetype');
  font-weight: bold;
}


:root {
  --blue-color: #3405F3;
  --blue-text-color: #131376;
  --primary-background: #0D0D0D;
  --yellow-color: #F7DB52;
  --lika-gold: #C0B89F;
  --lika-grey: #AFAFAF;
  --grey-text: #797979;
  --navbar-height: 60px;
  --app-height: 100%;
  --fs-base: 24px;
  --fs-sm-base: 18px;
  --fs-xsm-base: 14px;
  --fs-xxsm-base: 13px;
  --width-phone: 640px;
}

$xs-mobile-breakpoint: 350px;
$small-desktop-height-breakpoint: 720px;
$mobile-breakpoint: 640px;
$tablet-breakpoint: 1024px;


* {
  font-family: 'Muli', sans-serif;
}

.zoom-image{
  transform: scale(1.12);
}

.font-muli {
  font-family: 'Muli', sans-serif !important;
  letter-spacing: 1.5px;
}
.no-letter-spacing{
  letter-spacing: unset;
}
.font-rexton {
  font-family: 'Rexton', sans-serif !important;
}
.text-grey{
  color : var(--grey-text)
}

.flex-col-reverse{
  flex-direction: column-reverse;
}

html {
  font-size: var(--fs-base) !important;
  background: #FAFAFA;
  overflow-x: hidden;
  scroll-behavior: smooth;
  p{
    white-space: pre-line;
  }
}
.zindex-2{
  z-index:2
}
body {
  background: #FAFAFA;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.fixed-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #f0f0f0; /* Add background to overlay other content while fixed */
}
.background-gold{
  background:var(--lika-gold)
}

.uppercase.text-gold{
  letter-spacing: 2.5px;
}

#control-height {
  height: 100vh;
  width: 0;
  position: absolute;
}

@media(max-height: $small-desktop-height-breakpoint) {
  html {
    font-size: var(--fs-sm-base) !important;
  }
}

@media(max-width: $tablet-breakpoint) {
  html {
    font-size: var(--fs-sm-base) !important;
  }
}

@media(max-width: $mobile-breakpoint) {
  html {
    font-size: var(--fs-xsm-base) !important;
  }
}

@media(max-width: $xs-mobile-breakpoint) {
  html {
    font-size: var(--fs-xxsm-base) !important;
  }
}

.yellow-text {
  color: var(--yellow-color);
}

.blue-text {
  color: var(--blue-text-color);
}

.app {

  .navbar {

    .navbar-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .text-max-width{
    max-width: 80%;
  }

  .wrapper {
    flex: 1;
    max-width: 1600px;
  }

  .navbar-padding {
    padding-top: var(--navbar-height);
  }

  .text-lika-grey{
    color: var(--lika-grey) !important;
  }
  .text-gold{
    color: var(--lika-gold);
  }
  .lika-button {
    background: transparent;
    color: var(--lika-gold);
    border : 2px solid var(--lika-gold);
    padding: 3px 28px;
    font-family: Muli;
    text-transform: uppercase;
    font-weight: bold;
    width: fit-content;
    border-radius: 14px;
  }

  .xfi-button-rounded {
    background: var(--yellow-color);
    color: black;
    padding: 10px 32px;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .special-dev {
    background: var(--yellow-color);
    color: black;
    padding: 5px 20px;
    text-transform: uppercase;
    font-weight: bold;
    width: fit-content;
    border-radius: 0px;
    margin-bottom: 10px;
    font-size: 0.6rem;
  }

  .link {
    color: inherit;
    text-decoration: none;
  }

  .extra-spacing {
    height: 200px;
    background: #FAFAFA;
    width: 100%;
  }

  .extra-spacing-transparent {
    height: 200px;
    background: transparent;
    width: 100%;
  }

  .border-left-yellow {
    border-left-width: 2px;
    border-color: var(--yellow-color);
  }

  .section-padding-top {
    padding: 4rem 0rem 0rem 0rem;
  }

  .section-padding {
    padding: 4rem 2rem;
  }

  .section-padding-sm {
    padding: 2rem 2rem;
  }

  @media(max-width: $mobile-breakpoint) {
    .section-padding {
      padding: 5rem 1.3rem 2rem 1.3rem;
    }
    .section-padding-top {
      padding: 2rem 0rem 0rem 0rem;
    }
    .extra-spacing {
      height: 100px;
    }
  }
}

.toast-message {
  z-index: 10000;
  pointer-event: none;
}

.fadeOut {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
}

.xfi-border {
  height: 2px;
  max-width: 100%;
  width: 100%;
  border: 0px solid black;
  font-size: 30px;
  background: linear-gradient(90deg, #FDDA25, #002bff);
  background-size: 300% 300%;

  -webkit-animation: Animation 4s ease infinite;
  -moz-animation: Animation 4s ease infinite;
  animation: Animation 4s ease infinite;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%
  }
  50% {
    background-position: 91% 100%
  }
  100% {
    background-position: 10% 0%
  }
}

@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%
  }
  50% {
    background-position: 91% 100%
  }
  100% {
    background-position: 10% 0%
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%
  }
  50% {
    background-position: 91% 100%
  }
  100% {
    background-position: 10% 0%
  }
}

.cardview {
  -webkit-box-shadow: 0 0 30px #00000066;
  box-shadow: 0 0 30px #00000044;
  border-radius: 20px;
}

.small-text-dev{
  max-width:550px;
}

.medium-text-dev{
  max-width:700px;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

.img-hover-zoom {
  transform: scale(1.05);
  transition: transform .5s ease;
}

.img-hover-zoom:hover {
  transform: scale(1.5);
}

.float {
  animation: float 3.5s ease-in-out infinite;
}

.rotating {
  -webkit-animation: rotating 40s linear infinite;
  -moz-animation: rotating 40s linear infinite;
  -ms-animation: rotating 40s linear infinite;
  -o-animation: rotating 40s linear infinite;
  animation: rotating 40s linear infinite;
}

@keyframes float {
  0% {

    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}