@use "../../app";
.features-container {
  background: var(--primary-background);

  .feature-card {
    width: 300px;
    height: 300px;
    position: relative;
    margin: 15px;


    .background {
      position: absolute;
      overflow: hidden;
      border-radius: 15px;
      width: 100%;
      height: 100%;

      img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
      }

      &:after {
        transition: opacity 0.3s;
        content: "";
        background: linear-gradient(to bottom, transparent 0%, black 100%);;
        opacity: 0.5;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }

    p {
      position: relative;
      text-align: center;
      pointer-events: none;
    }
    .on-hover{
      display: none;
    }
  }
  .feature-card:hover{
    .on-hover{
      display: block;
    }
    .background {
      &:after {
        opacity: 1;
      }
    }
  }

  @media(max-width: app.$mobile-breakpoint) {
    .feature-card {
      width: 200px;
      height: 200px;
    }
  }

}