@use "../../app";

.home {

  .image-container {
    position: relative;
    width:100%;
  }

  .mockup {
    position: relative;
    min-width:300px;
    width:400px;
  }

  .mockup-1 {
    left: 10%;
    top:0px;

  }

  .mockup-2 {
    left: -10%;
    top:0px;
    position: absolute !important;
  }
  @media (max-width: 768px) {
    .mockup {
      position: relative;
    }
  }
  .image-container {
    position: relative;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  #image-section {
    border-radius: 0.5rem;
  }

  .polaroid {
    position: absolute;
    width: 220px; // Adjust size as needed
    height: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border: 10px solid white;
  }

  .top-right {
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }

  .mid-right {
    top: 25%;
    right: 0;
    transform: translateX(50%) translateY(-25%);
  }

  .mid-left {
    bottom: 25%;
    left: 0;
    transform: translateX(-50%) translateY(25%);
  }

  .bottom-left {
    bottom: 0;
    left: 0;
    transform: translateX(-50%) translateY(50%);
  }

  .mid-bottom-left {
    bottom: 50%;
    left: 0;
    transform: translateX(-50%) translateY(50%);
  }

  .rotate-3 {
    transform: rotate(3deg);
  }

  .rotate-neg-2 {
    transform: rotate(-2deg);
  }

  .rotate-2 {
    transform: rotate(2deg);
  }

  .rotate-neg-3 {
    transform: rotate(-3deg);
  }

  .rotate-1 {
    transform: rotate(1deg);
  }

  @media (max-width: 768px) {
    .polaroid {
      width: 100px; // Adjust size for smaller screens
    }

    .top-right {
      top: 10%;
    }

    .mid-right {
      top: 30%;
    }

    .mid-left {
      bottom: 30%;
    }

    .bottom-left {
      bottom: 10%;
    }

    .mid-bottom-left {
      bottom: 50%;
    }
  }

  @media (max-width: 480px) {
    .polaroid {
      display: none; // Hide images on very small screens if necessary
    }

    #image-section {
      background: rgba(255, 255, 255, 0.9); // Add a semi-transparent background
      padding: 16px;
      border-radius: 8px;
    }
  }

  #image-section {
    position: relative;
    overflow: hidden;
  }

  .scroll-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    transform: translate(-150%, -50%) rotate(0deg); /* Start off-screen */
    transition: transform 1s ease-out;
    opacity: 0;
  }

  .memory-album {
    position: relative;

    .memory-card {
      width: 80%;
      max-width: 400px;
      position: relative;
      transition: 2s all;

      &.flipped {
        position: absolute;
        bottom: 0px;
        left: 30px;
        transform-origin: 30% 100%;

      }

      &.animate-flip {
        -webkit-transform: rotate(30deg);
        transform:rotate(30deg);
      }

      @media(max-width: app.$mobile-breakpoint) {
        &.flipped {
          position: absolute;
          bottom: -20px;
          left: 30px;
          transform-origin: 100% 80%;

        }

        &.animate-flip {
          -webkit-transform: rotate(20deg);
          transform:rotate(20deg);
        }
      }

    }

    @media(max-width: app.$tablet-breakpoint) {
      .memory-card {
        max-width: 300px;
      }
    }
    @media(max-width: app.$mobile-breakpoint) {
      .memory-card {
        max-width: 200px;
      }
    }

  }

  .features-container {
    background: var(--primary-background);

    .feature-card {
      width: 300px;
      height: 300px;
      position: relative;
      margin: 15px;

      .background {
        position: absolute;
        overflow: hidden;
        border-radius: 15px;
        width: 100%;
        height: 100%;

        img {
          border-radius: 15px;
          width: 100%;
          height: 100%;
        }

        &:after {
          content: "";
          background: linear-gradient(to bottom, transparent 0%, black 100%);;
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }

      p {
        position: relative;
        text-align: center;
        pointer-events: none;
      }
    }

    @media(max-width: app.$mobile-breakpoint) {
      .feature-card {
        width: 200px;
        height: 200px;
      }
    }

  }

  .comingsoon-section {
    background: var(--primary-background);

    .card {
      background: white;
      border-radius: 20px 20px 0px 0px;
    }
  }

  .lika-meaning-section {
    background-image: url("../../assets/img/lika_meaning_background.jpg");
    background-position: center;
    background-size: cover;
  }
}