@use "../../app";
/* import your colors... */
.loading{
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height: var(--app-height);
  background:var(--primary-background);
  z-index: 10000;
  transition: all 1s ease;
  .container{
    transition: all 1s ease;
    height: var(--app-height);
  }
}
