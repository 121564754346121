@use "../../app";

.payment {
  background: var(--primary-background);
  min-height: var(--app-height);
  display: flex;
  align-items: center;
  justify-content: center;
  .download-button{
    max-width:200px
  }

}