@use "../../app";

.home {
  .memory-album {
    position: relative;

    .memory-card {
      width: 80%;
      max-width: 400px;
      position: relative;
      transition: 2s all;

      &.flipped {
        position: absolute;
        bottom: 0px;
        left: 30px;
        transform-origin: 30% 100%;

      }

      &.animate-flip {
        -webkit-transform: rotate(30deg);
        transform:rotate(30deg);
      }

      @media(max-width: app.$mobile-breakpoint) {
        &.flipped {
          position: absolute;
          bottom: -20px;
          left: 30px;
          transform-origin: 100% 80%;

        }

        &.animate-flip {
          -webkit-transform: rotate(20deg);
          transform:rotate(20deg);
        }
      }

    }

    @media(max-width: app.$tablet-breakpoint) {
      .memory-card {
        max-width: 300px;
      }
    }
    @media(max-width: app.$mobile-breakpoint) {
      .memory-card {
        max-width: 200px;
      }
    }

  }

  .features-container {
    background: var(--primary-background);

    .feature-card {
      width: 300px;
      height: 300px;
      position: relative;
      margin: 15px;

      .background {
        position: absolute;
        overflow: hidden;
        border-radius: 15px;
        width: 100%;
        height: 100%;

        img {
          border-radius: 15px;
          width: 100%;
          height: 100%;
        }

        &:after {
          content: "";
          background: linear-gradient(to bottom, transparent 0%, black 100%);;
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }

      p {
        position: relative;
        text-align: center;
        pointer-events: none;
      }
    }

    @media(max-width: app.$mobile-breakpoint) {
      .feature-card {
        width: 200px;
        height: 200px;
      }
    }

  }

  .comingsoon-section {
    background: var(--primary-background);

    .card {
      background: white;
      border-radius: 20px 20px 0px 0px;
    }
  }

  .lika-meaning-section {
    background-image: url("../../assets/img/lika_meaning_background.jpg");
    background-position: center;
    background-size: cover;
  }
}