@use "../../app";

.terms {
  .header-section {
    background: var(--primary-background);
    text-align: center;
    p{
      color: white;
    }
  }
  h1, h2, h3 {
    color: #333;
  }
  h2{
    padding-top:20px;
  }
  h1 {
    text-align: center;
  }
  ul {
    margin: 10px 0;
    padding: 0 20px;
    list-style-type: none;
  }
  li {
    margin-bottom: 8px;
    list-style-type: none;
  }
  a {
    color: #0066cc;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}