@use "../../app";
.navbar {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  padding-top:15px;

  #dropdown{
     background: var(--primary-background);
  }
  .wrapper {
    padding: 10px 30px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      float: left;
      padding: 8px 16px;


      a {
        color: white;
        transition: 0.2s ease;
        &:hover{
          color:var(--lika-gold)
        }
      }
    }

    .item {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;

      img {
        height: 100%;
        min-height:50px;
        padding: 8px 0px;
      }
    }

    .left {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 25px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 25px;

      .icons {
        display: flex;
        gap: 15px;
        color: #777;
        cursor: pointer;

        .cartIcon {
          position: relative;

          span {
            font-size: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #2879fe;
            color: white;
            position: absolute;
            right: -10px;
            top: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.navbar.is-sticky{
  padding-top:0px !important;
  position: fixed;
  background:var(--primary-background);
}
