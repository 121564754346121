@use "../../app";

.contact {
  .partners-section {
    background: var(--primary-background);
  }
  .form-section {
    background: var(--primary-background);
    .bg{

      background-color : #1A1A1A;
      .bg-image{

        background-size: cover;
        background-position: center;
        background-image: url("../../assets/img/contact_us_thumbnail.jpg");
        border-radius: 30px 0px 0px 30px;
      }
    }

    .form-wrapper{
      background:#1A1A1A;
      padding: 90px 30px;
      .form{
        max-width: 600px;
      }
      input{
        border-color : #707070;
        color:white;
      }
      textarea{
        border-color : #707070;
        color:white;
      }
      label{
        color:#797979;
      }
    }
  }
}