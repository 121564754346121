@use "../../app";

.terms {
  .header-section {
    background: var(--primary-background);
    text-align: center;
    p{
      color: white;
    }
  }

  ul{
    list-style: decimal;
  }
}