@use "../../app";

.policy {
  ol{
    list-style: decimal;
    padding-top:12px;
  }
  ul{
    list-style: decimal;
  }
  p{
    padding: 5px;
  }

  .header-section {
    background: var(--primary-background);
    text-align: center;
    p{
      color: white;
    }
  }
}