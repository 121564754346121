@use "../../app";
/* import your colors... */
.header{
  height: var(--app-height);
  background:var(--primary-background);
  .container{
    width: 100vw;
    height: 100%;
    display: flex;
    transition: all 1s ease;

      .content{
        padding-top:10%;
      }
  }

  .background-pattern{
    position: absolute;
    left: 30px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height:540px;

  }
  .transitionsItem {
    overflow: hidden;
    width: 100%;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 4em;
    font-weight: 800;
    text-transform: uppercase;
    will-change: transform, opacity, height;
    white-space: nowrap;
    cursor: pointer;
    line-height: 80px;
  }

  #mobile-mockup{
    max-width: 100%;
    height: fit-content;
    object-fit: contain;
    max-width: 500px;
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
  }
  @media(max-height: app.$small-desktop-height-breakpoint) {
    #mobile-mockup {
      max-width: 400px;
      width: 90%;
      height: auto;
      padding-top: 30px;
    }

    .background-pattern{
      position: absolute;
      left: 0px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height:460px;

    }
  }
  @media(max-width: app.$mobile-breakpoint) {
    #mobile-mockup{
      max-width: 230px;
      width: 90%;
      height: auto;
      padding-top:30px;
    }

    .background-pattern{
      position: absolute;
      left: 0px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height:460px;

    }
  }

  #particle-canvas{
    position: absolute;
    top: 0;
    left: 0;
  }

  .in5-logo{
    position:absolute;
    bottom:30px;
    right: 30px;
    width:40px;
  }
}
