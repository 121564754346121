@use "../../app";

.about {
  .ourstory-section {
    background: var(--primary-background);
  }

  .ourmission-section {
    background: var(--primary-background);
  }

  .fromthefounder-section {
    background: var(--primary-background);
    padding-bottom: 60px;
  }


  .carousel-container {
    background: var(--primary-background);

    .memory-card {
      width: 300px;
      height: 300px;
      position: relative;
      margin: 15px;
      background: var(--primary-background);
      .background {
        position: absolute;
        overflow: hidden;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        background: var(--primary-background);
        img {
          border-radius: 15px;
          width: 105%;
          height: 105%;
        }

      }

      p {
        position: relative;
        text-align: center;
        pointer-events: none;
      }
    }

    @media(max-width: app.$mobile-breakpoint) {
      .memory-card {
        width: 200px;
        height: 200px;
      }
    }
  }

  .comingsoon-section {
    background: var(--primary-background);

    .card {
      background: white;
      border-radius: 20px 20px 0px 0px;
    }
  }

  .likaforyou-section {
    background-image: url("../../assets/img/mountains_lika.jpg");
    background-size: cover;
    background-position: center;
    .store-button{
      max-height: 70px;
    }
  }
}